$('#header .js-eventClick-open-child').click(function(){
  if ($('#body').hasClass('is-active-menu')){
    $(this).toggleClass('is-open');
    $(this).siblings().removeClass('is-open');
  }
});


$(document).keyup(function(e) {
  if ($('#body').hasClass('is-active-menu')) {
    if (e.keyCode === 27) { $('#js-header__menu-mobile').click();}
  }
});

$('#js-body__header__menu-mobile').click(function(){
  $('#js-header__menu-mobile').click();
});

$('#js-header__menu-mobile').click(function(){
  $('#body').toggleClass('is-active-menu');
  $(this).toggleClass('is-active');
  $('.header__layer').toggleClass('is-active');
  if (!$('.header__layer').hasClass('is-active')) {
    $('.header__layer').addClass('no-active');
  } else {
    $('.header__layer').removeClass('no-active');
  }
});





var selector_producto = document.querySelectorAll('.item-qty');
var sumaTotal;
selector_producto.forEach( function(element, index) {
  selector_producto[index].addEventListener('input',function(){
    sumaTotal();
  });
});

function sumaTotal(){
  var qty = document.getElementById('qty').value;
  var total = document.getElementById('total').dataset.total;
  var cantidad = qty * total; 
  if (cantidad>=total){
    document.getElementById('total').textContent = Number(cantidad).toFixed(2)+' €';
    // console.log(Number(cantidad).toFixed(2));
  } 
}
$("#qty").change(function() {
  sumaTotal();
  if (document.getElementById('qty').value<1){
    document.getElementById('qty').value = 1;
  }
});

$(document).on('click', '.js-qty-plus', function () {
  $(this).prev().val(+$(this).prev().val() + 1);
  sumaTotal();
});
$(document).on('click', '.js-qty-minus', function () {
  if ($(this).next().val() > 1) {
    $(this).next().val(+$(this).next().val() - 1);
    sumaTotal();
  }
});

